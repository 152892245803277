<template>
    <router-view/>
</template>
<script>
import { useHead } from "@vueuse/head";
export default{
  name: "App",
  setup()
  {
    useHead({
        title: 'Dubai GPT'
    })
  }
  
  // created() {
  //   alert(process.env.VUE_APP_ROOT_API)
  // },
}
</script>
