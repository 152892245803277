<template>
    <section class="card h-100 p-0">
        <div class="card-header bg-transparent border-0 px-0 shadow">
            <div class="container">
                <nav class="d-flex gap-2 overflow-x-auto w-100">
                    <router-link class="button button_sm" :to="{ name:'search', query: { q: $route.query.q } }">All</router-link>
                    <router-link class="button button_sm" :to="{ name:'SearchWebComponent', query: { q: $route.query.q } }">Web</router-link>
                    <router-link class="button button_sm" :to="{ name:'SearchImageComponent', query: { q: $route.query.q } }">Image</router-link>
                    <router-link class="button button_sm" :to="{ name:'SearchVideoComponent', query: { q: $route.query.q } }">Video</router-link>
                    <router-link class="button button_sm" :to="{ name:'SearchNewsComponent', query: { q: $route.query.q } }">News</router-link>
                    <router-link class="button button_sm" :to="{ name:'SearchGPTComponent', query: { q: $route.query.q } }">GPT</router-link>

                </nav>
                <p class="text-secondary fw-light m-0 p-0 text-start">Available data based on <span class="fw-bold">{{ $route.query.q }}</span></p>
            </div>
        </div>
        <div class="card-body overflow-auto p-0">
            <div class="container my-3">
                <div class="card border-0">
                    <div class="card-body border-0">
                        <div class="row g-3" v-if="search_engine_response != null">
                            <div v-for="res in search_engine_response.value" :key="res" class="card card-body mb-3 border-0 shadow-sm bg-white">
                                <div class="d-flex align-items-top ">
                                    <div class="flex-shrink-0 me-3" v-if="res.image != null">
                                        <img width="120" height="120" :src="res.image.thumbnail.contentUrl" class="object-fit-cover border rounded">
                                    </div>
                                    <div class="flex-grow-1">
                                        <h5 class="mb-0 h6 text-secondary"><a :href="res.url" target="_blank" class="text-decoration-none">{{ res.name }}</a></h5>
                                        <p class="badge bg-info  my-1">{{ res.provider[0].name }}</p>
                                        <p class="text-secondary my-1">{{ res.description }}</p>
                                        <span><i class="bi bi-calendar3"></i> {{ new Date(res.datePublished).toDateString() }}</span>
                                    </div>                                    
                                </div>
                            </div>
                            <div v-if="search_engine_response.value.length == 0">
                                <p class="alert alert-warning text-center">Ops no news found</p>
                            </div>                            
                        </div>
                        <div v-else>
                            <div v-if="search_engine_loading">
                                <div class="d-flex align-items-center mb-3">
                                    
                                    <div class="flex-shrink-0 me-3">
                                        <Skeleton width="120px" height="120px"></Skeleton>
                                    </div>
                                    <div class="flex-grow-1">
                                        <Skeleton class="mb-2"></Skeleton>
                                        <Skeleton height="100px"></Skeleton>
                                    </div>

                                </div>

                                <div class="d-flex align-items-center mb-3">
                                    
                                    <div class="flex-shrink-0 me-3">
                                        <Skeleton width="120px" height="120px"></Skeleton>
                                    </div>
                                    <div class="flex-grow-1">
                                        <Skeleton class="mb-2"></Skeleton>
                                        <Skeleton height="100px"></Skeleton>
                                    </div>

                                </div>

                                <div class="d-flex align-items-center">
                                    
                                    <div class="flex-shrink-0 me-3">
                                        <Skeleton width="120px" height="120px"></Skeleton>
                                    </div>
                                    <div class="flex-grow-1">
                                        <Skeleton class="mb-2"></Skeleton>
                                        <Skeleton height="100px"></Skeleton>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
        <div class="card-footer d-flex justify-content-center bg-transparent">
            <div class="searchboxForm">
                <form class="d-flex gap-1 align-items-center" id="searchBox" @submit.prevent="searching()">
                    <div class="d-flex w-100 bg-transparent rounded">
                        <input type="search" autocomplete="off" @keyup="getKeywordSuggestions()" autofocus required name="q" list="suggetions" class="searchInput form-control form-control-lg border"  :class="{ active: question_form.question, 'text-danger': hasError }" :placeholder="$t('placeholder_serach')" aria-label="With textarea" v-model="question_form.question">
                        <datalist id="suggetions"><option :value="suggestion" v-for="suggestion in search_keyword_suggesion" :key="suggestion">{{suggestion}}</option></datalist>
                    </div>
                    <div class="d-flex flex-row justify-content-center align-items-center gap-2">
                        <button type="button" v-if="isMicAvailable" class="btn btn-outline-default waves searchButton" :class="{ active: isListening }" @click="toggleRecognition" :title="isListening ? 'Stop Listening' : 'Start Listening'">
                            <i class="bi bi-mic-fill h3 icon"></i>
                        </button>
                        <button class="btn btn-outline-default searchButton" type="submit" >
                            <i class="bi bi-send-fill h3"></i>
                        </button>
                    </div>
                </form>
            </div>            
        </div>
    </section>
</template>
<script>
import { useHead } from "@vueuse/head";
import { useRoute } from 'vue-router';
import axios from 'axios';
import Skeleton from 'primevue/skeleton';
import { containsBadWord } from '../../../badWords';
import { ref } from 'vue';

export default{
    name : "SelectNews",
    components:{
        Skeleton,
    },
    data() {
        return {
        conversation_id : this.$store.getters.conversationId,
        conversation_data : '',
        search_engine_response: null,
        search_keyword_suggesion: [],
        question_form : {
            question : ""
        },
        search_engine_loading: false,
        wikipedia_response_loading: false,
        gpt_loading: false,

        isListening: false,
        recognition: null,
        isMicAvailable : true,

        search_result_type:"web",
        search_popup: false,
        search_popup_data: {},

        streamedText:'',
        gpt_task: false
        }
    },
    created()
    {
        // this.getConversations()
        this.get_search_keyword();
        this.searching();   
    },
    methods: {
        get_search_keyword()
        {
            const route = useRoute()
            this.question_form.question = route.query.q
        },
        
        removeCharacter(data) {
            try
            {
                if(data != "")
                {
                    return data.replace("?", "");
                }
                else
                {
                    return data
                } 
            }
            catch (error)
            {
                console.error(error);
                // Expected output: ReferenceError: nonExistentFunction is not defined
                // (Note: the exact output may be browser-dependent)
                return data
            }
        },

        badWordFilter()
        {
            return containsBadWord(this.question_form.question);
        },

        toggleRecognition()
        {
            this.checkMicAvailability()
            if (this.isMicAvailable)
            {
                if (this.isListening)
                {
                    this.recognition.stop();
                }
                else
                {
                    this.recognition.start();
                }
            }
            else
            {
                this.$toast.error("Mic is not available", {
                    icon: "dangerous",
                }); 
            }            
        },

        checkForCommand(result)
        {
            const t = result.transcript;
            if (t.includes('stop'))
            {
                this.recognition.stop();
            }
        },

    
        checkMicAvailability()
        {
            navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
            // Code for success
                console.log(stream)
                this.isMicAvailable = true
                this.checkMicAvailability()
            }).catch(err => {
                console.log(err)
                this.isMicAvailable = false
            })
        },

        async getSearchEngineResponse(type)
        {
            // calling api
            if(this.question_form.question != "")
            {
                this.search_engine_response = null;
                this.search_engine_loading = true;
                const API_URL = `${process.env.VUE_APP_ROOT_API_ENDPOINT}/user/guest/bing_search/${type}/${this.removeCharacter(this.question_form.question)}/`;
                await axios.get(API_URL).then((response) => {
                    if(response.status == 204)
                    {
                        // this.$toast.error(response.data.message, {
                        //     icon: "dangerous",
                        // }); 
                        this.search_engine_loading = false;
                    }
                    else if(response.status == 200)
                    {
                        this.search_engine_response = response.data.data
                        this.search_result_type = response.data.search_type
                        this.search_engine_loading = false;
                    }
                })
                .catch(() => {
                // Handle errors
                    // console.log(error);
                    // this.$toast.error("Ops something is wrong. try again later", {
                    //     icon: "dangerous",
                    // }); 
                    this.search_engine_loading = false;
                }); 
            }
            // calling api
        },

        async searching()
        {
            if(this.question_form.question != "")
            {
                this.updateTitle(this.removeCharacter(this.question_form.question)+" - Video Search");
                this.$router.push({path: this.$route.fullPath, query: {q: this.removeCharacter(this.question_form.question)} });

                let wordcheker = containsBadWord(this.question_form.question)
                if(!wordcheker)
                {
                    await this.getSearchEngineResponse("news");
                }
                else
                {
                    // alert(`Please avoid using offensive or inappropriate language (${wordcheker}) as it's against Dubai's government rules.`);
                    this.$toast.error(`Please avoid using offensive or inappropriate language (${wordcheker}) as it's against Dubai's government rules.`); 
                }
            }
            else
            {
                this.$toast.error("Questions are required"); 
            }
        },

        searchPopup(data)
        {
            this.search_popup = !this.search_popup
            this.search_popup_data = data
        },

        getKeywordSuggestions()
        {
            if(this.question_form.question != "")
            {
                const API_URL = `${process.env.VUE_APP_ROOT_API_ENDPOINT}/user/search/suggestions/${this.question_form.question}/`;
                const headers = { 
                    "Authorization": `Bearer ${this.$store.getters.getToken}`,
                };

                axios.get(API_URL, {headers})
                .then((response) => {
                    if(response.status == 204)
                    {
                        // this.$toast.error(response.data.message, {
                        //     icon: "dangerous",
                        // }); 
                    }
                    else if(response.status == 200)
                    {
                        this.search_keyword_suggesion = response.data.suggestion
                    }

                })
                .catch(() => {
                    this.search_engine_loading = false;
                });
            }
        }
    },
    setup()
    {
        const pageTitle = ref('News Search - Dubai GPT');

        useHead({
            title: pageTitle
        });

        // Function to change the title
        function updateTitle(newTitle) {
            pageTitle.value = newTitle;
        }

        return {
            updateTitle,
        };
    },
    mounted()
    {
        // this.searching()
        // to use mic
        this.recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
        this.recognition.continuous = true;
        this.recognition.interimResults = true;

        this.recognition.onstart = () => {
            console.log('Recognition Started');
            this.isListening = true;
        };

        this.recognition.onend = () => {
            console.log('Recognition Stopped');
            this.isListening = false;
        };

        this.recognition.onresult = (evt) => {
            let finalTranscript = '';
            for (let i = 0; i < evt.results.length; i++) {
                const result = evt.results[i];
                if (result.isFinal) {
                finalTranscript += result[0].transcript + ' ';
                this.checkForCommand(result[0]);
                }
            }
            this.question_form.question = finalTranscript.trim();
        };
        // to use mic
    },    
}
</script>