<template>
    <div v-if="$store.getters.getLanguage == 'en'">
        <AboutInEnglish />
    </div>
    <div v-if="$store.getters.getLanguage == 'ar'">
        <AboutInArabic />
    </div>
</template>
<script>
import AboutInEnglish from "@/views/landing/AboutUs_en.vue";
import AboutInArabic from "@/views/landing/AboutUs_ar.vue";

export default{
    name: "AboutUs",
    components:{
        AboutInEnglish,
        AboutInArabic
    }
}
</script>

<style>
dl dt 
{
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
}
dl dd
{
    font-size: 15px;
    margin-bottom: 10px;
    margin-left: 20px;
}
ul, ol
{
    margin-left: 20px;
}
</style>