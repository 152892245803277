<template>
    <section class="container">
        <div class="card border-0">
            <div class="card-header border-0 p-4">
                
                <h1 class="h4">بيان شروط وخصوصية المستخدمين</h1>
                               
            </div>
            <div class="card-body p-4">
                <h1 class="h5">مرحبًا بك في DUBAI GPT!</h1>
                <hr>
                <div>
                    <dl>
                        <dd>تحياتنا وتحياتنا من DUBAI GPT (من الآن فصاعدًا "نحن" أو "نحن" أو "نا"). يعد خصوصيتك أمرًا مهمًا للغاية بالنسبة لنا، ونحن ملتزمون بحماية معلوماتك الشخصية. يوضح هذا البيان الخصوصية بروتوكولاتنا فيما يتعلق بجمع وحماية معلوماتك أثناء استخدامك لخدماتنا. من خلال استخدامك لخدماتنا أو الوصول إليها، فإنك توافق على الشروط والأحكام المنصوص عليها في هذا البيان الخصوصية. يرجى مراجعة هذه السياسة بكل تفصيل لفهم مدى نهجنا في الحفاظ على معلوماتك.</dd>
                        <dt>بقبول البنود،</dt>
                        <dd>تقر بأنك قمت بمراجعة وفهم وموافقة على الالتزام بالشروط والأحكام المحددة في بيان الخصوصية هذا عند استخدام خدماتنا. أنت مسؤول عن مراجعة هذا البيان بشكل دوري، والذي قد يتغير في أي وقت. يعني استمرار استخدام خدماتنا بعد أي تغيير موافقتك على الشروط المعدلة.</dd>
                        <dt>تنفيذ الخدمات</dt>
                        <dd>
                            <p>تقدم منظمتنا مجموعة واسعة من الخدمات، تشمل البرمجيات، والمواقع الإلكترونية، وتطبيقات الهواتف المحمولة، ومنصات رقمية أخرى متنوعة.</p>
                            <p>توافق، من خلال استخدام خدماتنا، على ما يلي:</p>
                            <ul>
                                <li>سأقدم بيانات دقيقة وشاملة وفقًا لطلبك.</li>
                                <li>الالتزام بالامتثال الكامل لجميع القوانين والتشريعات ذات الصلة. يرجى الامتناع عن المشاركة في أي محاولة قد تتسبب في مشاكل أو أضرار أو إزعاج أو تلف لخدماتنا، وكذلك للشبكات والأنظمة المرتبطة بها.</li>
                                <li>الموافقة على أي شروط خدمة خاصة أو إرشادات استخدام مرتبطة بخدمات أو ميزات DUBAI GPT معينة.</li>
                            </ul>
                        </dd>
                        <dt>سلوك المستخدمين</dt>
                        <dd>
                            <p>توافق، من أجل الحفاظ على بيئة احترامية وآمنة لجميع المستخدمين، على عدم:</p>
                            <ul>
                                <li>المشاركة في أي أنشطة غير قانونية أو احتيالية أو ضارة.</li>
                                <li>إصدار البيانات أو نشر أي مواد غير قانونية أو مسيئة أو تنتهك الحقوق.</li>
                                <li>الانخراط في الاحتيال أو التصريح بانتمائك بشكل غير صادق إلى DUBAI GPT ممنوع بشكل صارم.</li>
                                <li>محاولة الوصول غير القانوني إلى أنظمتنا أو بياناتنا أو حساباتنا تشكل تهديدًا محتملًا.</li>
                                <li>استخدام الطرق الآلية، مثل الخلاطات أو الروبوتات، للوصول غير المصرح به إلى خدماتنا.</li>
                                <li>يجب عدم التحرش أو المضايقة أو المشاركة في أي سلوك ضار موجه إلى مستخدمين آخرين.</li>
                                <li>جمع أو تخزين المعلومات الشخصية التابعة لمستخدمين آخرين بدون إذن.</li>


                            </ul>
                        </dd><dt>الملكية الفكرية (IP)</dt>
                        <dd>
                            <p>تحمي قوانين الملكية الفكرية جميع المواد والمحتوى على DUBAI GPT، بما في ذلك على سبيل المثال لا الحصر النصوص والرسومات وأسماء الشركات والصور والبرمجيات والمواد السمعية والبصرية. من دون موافقتنا الكتابية المسبقة أو موافقة أصحاب الحقوق الضرورية، ليس لك الحق في استخدام أو استنساخ أو توزيع أو تعديل أو إنشاء أعمال مشتقة من محتوانا.</p>
                            <p>تمنح DUBAI GPT ترخيصًا عالميًا وغير حصريًا ومجانيًا لاستخدام وعرض واستنساخ وتوزيع المحتوى الذي تقوم بتحميله أو تقديمه إلى خدماتنا، مع الاحتفاظ بملكية المواد.</p>
                        </dd>
                        <dt>الحفاظ على الخصوصية</dt>
                        <dd>
                            <p>نضع أهمية خاصة على خصوصيتك وملتزمون بحماية معلوماتك الشخصية. نوضح في بيان الخصوصية لدينا كيفية الحصول على معلوماتك واستخدامها وكشفها وحمايتها. توافق على ممارسات البيانات لدينا كما هو موضح في بيان الخصوصية من خلال استخدام خدماتنا.</p>
                        </dd>
                        <dt>[تنصيح]</dt>
                        <dd>
                            <p>يسعى DUBAI GPT بجدية لضمان استمرارية وتوفر خدماتنا. نحن، مع ذلك، لا نضمن خدمة غير متوقفة أو خالية من الأخطاء. نحتفظ بحق تعليق أو تقييد الوصول إلى خدماتنا مؤقتًا بدون إشعار مسبق للتحديثات أو الصيانة أو أسباب أخرى.</p>
                        </dd>
                        <dt>المحتوى من جهات خارجية: </dt>
                        <dd>
                            <p>قد تتضمن خدماتنا روابط إلى مواقع الويب أو التطبيقات أو المحتوى التابعة لجهات خارجية. لا تتحمل DUBAI GPT مسؤولية عن ملاءمة أو قانونية أو دقة المحتوى المذكور أعلاه. بالنسبة للتفاعلات مع المحتوى التابع لجهات خارجية، تنطبق شروط وسياسات تلك الجهة الثالثة.</p>
                            <p>يرجى ملاحظة أنه على الرغم من أننا نبذل كل جهد لتقديم معلومات دقيقة وحديثة، إلا أننا لا نضمن دقة أو موثوقية المحتوى المتاح على DUBAI GPT. تعلن هنا أن أي اعتماد على المعلومات المقدمة يتم على مسؤوليتك الشخصية بالكامل.</p>
                            <p>نقوم بتنفيذ تدابير أمنية كافية لحماية خدماتنا وبياناتك. مع ذلك، لا يمكن ضمان أمان المعلومات المخزنة على أنظمتنا أو المرسلة عبر الإنترنت. من واجبك ضمان خصوصية بيانات تسجيل الدخول الخاصة بك وإعلامنا على الفور بأي نشاط غير مصرح به قد يحدث على حسابك.</p>

                        </dd>
                        <dt>[قيود المسؤولية]</dt>
                        <dd>
                            <p>لا تتحمل DUBAI GPT وشركاتها التابعة ومديريها وموظفيها ووكلائها مسؤولية، إلى أقصى حد يسمح به القانون المعمول به، عن أي أضرار مباشرة أو غير مباشرة أو عرضية أو خاصة أو نتيجية أو عقابية قد تنشأ عن أو في اتصال مع استخدامك لخدماتنا، حتى لو تم إبلاغنا بإمكانية وقوع مثل هذه الأضرار. يقتصر مسؤوليتنا إلى أقصى حد يسمح به القانون.</p>
                        </dd>
                        <dt>التعويض</dt>
                        <dd>
                            <p>من خلال استخدام خدماتنا أو انتهاك هذه الاتفاقية، تتعهد بإبقاء DUBAI GPT وشركاتها التابعة ومديريها وموظفيها ووكلائها براءة من أي مطالبات أو مسؤوليات أو أضرار أو خسائر أو تكالي</p>
                            <p>ف ونفقات (بما في ذلك رسوم المحاماة) التي قد تنشأ عن أو في اتصال مع استخدامك لخدماتنا أو انتهاك هذه البنود أو أي حقوق لطرف ثالث.</p>
                        </dd>
                        <dt>القانون الساري</dt>
                        <dd>
                            <p>بغض النظر عن مبادئ تعارض القوانين الخاصة به، يجب أن يكون هذا البيان الخاص بالخصوصية واستخدامك لخدمات DUBAI GPT محكومًا ومعرفًا وفقًا لقوانين دولة الإمارات العربية المتحدة. الولاية القضائية على أية نزاعات قد تنشأ فيما يتعلق بأو بموجب هذا البيان الخاص بالخصوصية يكون للمحاكم الموجودة في دبي، دولة الإمارات العربية المتحدة.</p>
                        </dd>
                        <dt>[معلومات الاتصال]</dt>
                        <dd>
                            <p>الرجاء مراسلتنا عبر البريد الإلكتروني Dubaigpt@9x لأي استفسارات أو مخاوف أو ملاحظات بخصوص هذا البيان الخاص بالخصوصية أو خدماتنا.</p>
                            <p>توافق على هذا البيان الخاص بالخصوصية وتؤكد أنك قد قرأته وفهمته ووافقت عليه من خلال استخدام خدمات DUBAI GPT. نحن ملتزمون بحماية خصوصيتك وتقديم تجربة مستخدم موثوقة وآمنة، حيث نقدر ثقتك بنا تقديرًا كبيرًا.</p>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
        
    </section>
</template>
<script>
export default{
    name: "TermsAndConditionsScreen"
}
</script>

<style>
dl dt 
{
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
}
dl dd
{
    font-size: 15px;
    margin-bottom: 10px;
    margin-left: 20px;
}
ul, ol
{
    margin-left: 20px;
}
</style>