<template>
  <div class="bg-transition-area">
    <div class="container">
      <div class="row align-items-center justify-content-center vh-100">
        <div class="col-md-12 col-lg-6 d-none d-lg-block">
          <img src="../../assets/infograph-1.png" class="w-100 pt-5 pt-md-0">
        </div>
        <div class="col-md">
          <form class="card h-100 p-5 auth_card border-0 shadow-lg" autocomplete="off" autosave="off" @submit.prevent="registerAction()">
            <div class="card-header bg-transparent border-0 text-center">
              <router-link class="text-decoration-none text-secondary" :to="{ name:'home' }"><img width="80" class="me-3" src="../../assets/logo.png"></router-link>
              <h3 class="h4 mt-3">WELCOME BACK</h3>
            </div>
            <div class="card-body text-start">
              <div class="mb-3">
                <label for="name" class="form-label">First Name</label>
                <input type="text" class="form-control" id="name" v-model="registerUser.first_name">
                <span class="text-danger w-100 text-end" v-if="registerUser.register_error && v$.registerUser.first_name.$error">
                  <span v-if="v$.registerUser.first_name.$errors[0].$message == 'Value is required'">First name is required</span>
                </span>
              </div>
              <div class="mb-3">
                <label for="last_name" class="form-label">Last Name</label>
                <input type="text" class="form-control" id="last_name" v-model="registerUser.last_name">
                <span class="text-danger w-100 text-end" v-if="registerUser.register_error && v$.registerUser.last_name.$error">
                  <span v-if="v$.registerUser.last_name.$errors[0].$message == 'Value is required'">Last name is required</span>
                </span>
              </div>
              <div class="mb-3">
                <label for="email" class="form-label">Email</label>
                <input type="email" class="form-control" id="email" v-model="registerUser.email">
                <span class="text-danger w-100 text-end" v-if="registerUser.register_error && v$.registerUser.email.$error">
                  <span v-if="v$.registerUser.email.$errors[0].$message == 'Value is required'">Email is required</span>
                  <span v-if="v$.registerUser.email.$errors[0].$message == 'Value is not a valid email address'">Please enter a valid email address</span>  
                </span>
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">Password</label>
                <input type="password" class="form-control" id="password" v-model="registerUser.password">
                <span class="text-danger w-100 text-end" v-if="registerUser.register_error && v$.registerUser.password.$error">
                  <span v-if="v$.registerUser.password.$errors[0].$message == 'Value is required'">Password is required</span>  
                </span>
              </div>
              <label class="mb-3">
                <input class="form-check-input me-2" type="checkbox" required><small>By creating an account you agree to <router-link :to="{ name: 'TermsAndConditionsPage' }" target="_blank"> Terms and Conditions</router-link></small>
              </label>
            </div>
            <div class="card-footer bg-white border-0 bg-transparent">
              <div class="d-grid gap-3 text-center">
                <button class="theme-button" v-on:click="registerAction()">Create an account</button>
                <!-- <button class="theme-button-outline" type="button"><img src="../../assets/google.svg" height="20"> Sign in with Google</button> -->
                <GoogleAuth></GoogleAuth>
                <p>Already have an account? <router-link class="text-dark text-decoration-none fw-bold" :to="{ name:'LoginView' }">Sign In</router-link></p>
                
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.navbar 
{
  display: none !important;
}

</style>
<script>  
import { useHead } from "@vueuse/head";
import useVuelidate from '@vuelidate/core';
import { required, minLength, email } from '@vuelidate/validators';
import axios from 'axios';

import GoogleAuth from "@/components/GoogleAuth.vue";

export default{
  name : "RegisterView",
  components:{
    GoogleAuth
  },
  data()
  {
    return {
      api_base_url : process.env.VUE_APP_ROOT_API,
      registerUser : {
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        register_error: false,
      }
    }
  },
  setup() {      
    useHead({
        title: 'Register - Dubai GPT'
    })

    return {
      v$: useVuelidate(),
    }
  },
  validations() {
    return {
      registerUser : {
        
        email: {
          required, email
        },
        first_name: {
          required
        },
        last_name: {
          required
        },
        password: { 
          required,
          minLength: minLength(8),
        },
      }
    }
  },
  methods:{
    async registerAction()
    {
      this.v$.registerUser.$touch();
      if (!this.v$.registerUser.$invalid) {
        this.registerUser.register_error = false;
        console.log("validated");
        // calling api
        const API_URL = `${process.env.VUE_APP_ROOT_API_ENDPOINT}/user/register/`;
        const formData = axios.toFormData(this.registerUser)

        axios.post(API_URL, formData)
        .then((response) => {
          
          if(response.status == 201)
          {
            this.$toast.success(response.data.message, {
              icon: "check",
            }); 
            this.$store.commit('SET_USER', response.data.data.user)
            this.$store.commit('SET_TOKEN', response.data.data.token)
            this.$store.commit('SET_AUTHENTICATED', true)
            this.$store.commit('SET_USER_AVATAR', response.data.data.user.avatar)
            this.$router.push({ name: 'DashboardScreen' })
          }
          else
          {
            this.$toast.error(response.data.message, {
              icon: "dangerous",
            });             
          }
          this.loading = false;
        })
        // calling api
      } else {
        console.log("not done");
        this.$toast.error("Please fill the required fields", {
            icon: "dangerous",
        });        
        this.registerUser.register_error = true;
      } 
    }
  }
}
</script>