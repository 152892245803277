<template>
    <!-- about -->
    <section id="aboutuspaage" ref="section2" class="py-5">
        <div class="container py-1">
            <div class="row align-items-center justify-content-center ">

                <div class="col-md-12">

                    <div class="row">
                        <div class="col-md-6">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1">
                                    <h3 class="sub-headline-decor-small">Dubai GPT – About Us</h3>
                                </div>
                                <div class="flex-shrink-0">
                                    <img src="../../assets/redux1.png">
                                </div>

                            </div>
                        </div>
                    </div>
                    <p class="description mt-3">Welcome Aboard! Welcome to the world of Dubai GPT, which today is rising to
                        be an unparalleled AI-powered chatbot and platform that can take over the Artificial Intelligence
                        Revolution in the best possible way ever. We at Dubai GPT are a team of professionals who are highly
                        driven and passionate AI specialists, experts, and, most importantly, AI lovers who are putting in
                        every possible effort to help Dubai GPT reach the forefront of transformative AI experiences,
                        solutions, and revolutions.</p>
                    <p class="description mt-3">As a state-of-the-art AI-powered platform, we take pride in our dedication,
                        immense love, and commitment to allowing artificial intelligence to become an integral part of
                        everyone’s life. We are committed to reshaping the whole digital landscape for the better. We also
                        focus on empowering businesses, brands, and individuals with the outstanding and endless
                        capabilities of Open AI’s GPT structure. We are a team driven by AI passion, AI innovation, and AI
                        expertise, thriving on an inexorable pursuit of AI excellence.</p>
                </div>

            </div>
            <div class="row ">

                <div class="col-md-12">
                    <div class="mt-2 py-5">
                        <div class="flex-grow-1">
                            <h3 class="sub-headline-decor-small">Who are we at Dubai GPT?</h3>
                        </div>
                        <p class="description mt-3">We at Dubai GPT are a team that cannot be boxed into the definition of AI solution providers as we rise above that and consider ourselves innovators in the tech world. We are the architects of a tech-driven future dedicated to crafting dynamic and intelligent systems capable of redefining the boundaries of what AI can genuinely achieve.</p>
                        <p class="description mt-3">Our team comprises seasoned professionals and AI experts, tech enthusiasts, and creative minds that pledge to bring together diverse expertise for Dubai GPT’s limitless growth and success in the AI world, paving the way for creative and innovative solutions that possess the power to exceed the ordinary.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row ">
                <div class="aboutusVision col-sm-3">

                </div>
                <div class="col-sm-9">
                    <div class="mt-5 py-5">
                        <div class="flex-grow-1">
                            <h3 class="sub-headline-decor-small">Our Vision at Dubai GPT</h3>
                        </div>
                        <p class="description mt-3">We at Dubai GPT are a team that cannot be boxed into the definition of
                            AI solution providers as we rise above that and consider ourselves innovators in the tech world.
                            We are the architects of a tech-driven future dedicated to crafting dynamic and intelligent
                            systems capable of redefining the boundaries of what AI can genuinely achieve.</p>
                        <p class="description mt-3">Our team comprises seasoned professionals and AI experts, tech
                            enthusiasts, and creative minds that pledge to bring together diverse expertise for Dubai GPT’s
                            limitless growth and success in the AI world, paving the way for creative and innovative
                            solutions that possess the power to exceed the ordinary.</p>
                    </div>
                </div>
            </div>
            <div class="row ">
                <div class="col-sm-9">
                    <div class="mt-5 py-5">
                        <div class="flex-grow-1">
                            <h3 class="sub-headline-decor-small">Our Mission at Dubai GPT</h3>
                        </div>
                        <p class="description mt-3">Dubai GPT is committed to transforming industries, businesses, and individuals’ lives by harnessing the infinite power of AI. We are also dedicated to crafting intelligent AI-driven solutions capable of redefining human-machine interactions and experiences. This has motivated us to do better at improving personalized digital experiences for nurturing creative innovations.</p>
                        <p class="description mt-3">Innovation is at our core, and through AI solutions, we are determined to offer people tech experiences that can seamlessly integrate into their lives and enhance their productivity, creativity, and overall human experience.</p>
                    </div>
                </div>
                <div class="aboutusVisiontwo col-sm-3">

                </div>
            </div>
        </div>
        <div class="container">
            <div class="row ">

                <div class="col-md-12">
                    <div class="mt-5 py-5">
                        <div class="flex-grow-1">
                            <h3 class="sub-headline-decor-small">Our Expertise</h3>
                        </div>
                        <p class="description mt-3">At Dubai GPT, our AI-driven solutions are not just about automating tasks but also revolutionizing industries and businesses to eventually foster creativity and lead people to newer growth and success possibilities. We thrive on groundbreaking ideas and are determined to set new standards of success in the AI landscape.</p>
                        <p class="description mt-3">Our expertise lies in crafting intelligent conversations, specializing in natural language processing. This helps enable machines to understand, interpret, and respond to human language in a way that feels real, authentic, meaningful, and intuitive. We leverage the power of GPT architecture to develop chatbots and conversations that are engaging and purposeful.</p>
                        <p class="description mt-3">Dubai GPT also excels at personalized digital experiences, focusing on catering to people for tailored conversational interfaces by leveraging the power of AI. Individuals can generate great content, analyze data, gain more information, and create more interactions as per their preferences and needs. Our proficiency and excellence in machine learning have also propelled us to harness AI’s power to build models that learn from data and evolve dynamically.</p>
                    </div>
                    <div class="px-3 flex-grow-1">
                        <h4 class="sub-headline-decor-small">Creating Revolutions through AI</h4>
                        <p class="description mt-3">Dubai GPT as a team is driven by creativity, which, according to us, has no boundaries, and so do our solutions with AI. We can proudly say that we are on the verge of revolutionizing industries by infusing AI into design, content creation, business, and artistic endeavors.</p>
                        <p class="description mt-3">Individuals can stay rest assured in generating compelling marketing copies, gaining assistance with new ideas, and ultimately creating new horizons for creative expression through Dubai GPT.</p>
                        <p class="description mt-3">We create revolutions with AI also because, at the very core, we are driven by honesty and authenticity through our AI solutions. We are committed to the ethical development of AI solutions and understand the responsibilities of shaping the future of technology.</p>
                        <p class="description mt-3">We prioritize fairness, accountability, and transparency in our practices and stay committed to responsible innovation to eventually contribute to society positively and improve the ethical standards for other contemporaries in the industry.</p>
                    </div>
                </div>
            </div>

            <div class="row ">
                <div class="col-md-12">
                    <div class="mt-5 py-5">
                        <div class="flex-grow-1">
                            <h3 class="sub-headline-decor-small">The Differentiating Factor at Dubai GPT</h3>
                        </div>
                        <p class="description mt-3">Dubai GPT stands distinctive from the rest in the industry A. for its technology standards and B. for its approach to AI. As a team, we are driven by our passion for tech innovations, a commitment to honest and ethical practices, and a solid vision to empower people, businesses, and industries through the transformative power of AI.</p>
                    <p class="description mt-3">Our one-of-a-kind AI solutions help individuals make data-driven decisions, motivate them to optimize processes, and gain a competitive edge in their respective niches and sectors.</p>
                    <p class="description mt-3">Are you ready to embark on the AI Revolution with Dubai GPT? We invite you to join our journey and jet off to the future of AI. Whether you are a business owner, creative mind, artist, student, or any other professional across any industry or niche, our AI system will assist in helping you create an impact with the AI solutions you receive from our platform.</p>
                    <p class="description mt-3">We welcome you to explore the endless possibilities of success with Dubai GPT.</p>
                </div>
            </div>

        </div>
    </div>
</section></template>
<script>
export default {
    name: "AboutUs"
}
</script>

<style>dl dt {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
}

dl dd {
    font-size: 15px;
    margin-bottom: 10px;
    margin-left: 20px;
}

ul,
ol {
    margin-left: 20px;
}
.aboutusVision{
  background: url("../../assets/campLight.png") left top;
  background-size: contain;
  background-repeat: no-repeat;
}
.aboutusVisiontwo{
  background: url("../../assets/campLight.png") left top;
  background-size: contain;
  background-repeat: no-repeat;
  transform: scaleX(-1);
}
</style>