<template>
  <h1 class="d-none">Welcome to Quran GPT</h1>
  <SearchForGuest></SearchForGuest>

  <section class="hero_slider" ref="section1" id="home">
    <div class="container">
      <div class="row align-items-center justify-content-center vh-100">
        
        <div class="col-md-6">
          <span class="label_box mb-2" data-aos="fade-up" data-aos-delay="10">{{ $t('text_engage_explore_evolve') }}</span>
          <h3 class="sub-headline-decor mt-3" data-aos="fade-up" data-aos-delay="50">
            <!-- <VueWriter :array="$t('unlock_limitless_conversations')" :eraseSpeed="1" :typeSpeed="10" /> -->
            {{ $t('text_unlock_limitless_conversations') }}
          </h3>
          
          <h1 data-aos="fade-up" data-aos-delay="100">
            <span v-html="$t('title')" class="headline-decor fw-bolder"></span>
            <!-- Dubai <span>GPT</span> -->
            <!-- <span class="d-inline-block"><VueWriter :array="title" :eraseSpeed="1" :typeSpeed="5" /></span> -->
          </h1>

          <div class="d-flex gap-2 mt-5 justify-content-center justify-content-md-start" data-aos="fade-up" data-aos-delay="200" v-if="!$store.getters.isAuthenticated">
            <router-link class="button button_dark" :to="{ name:'RegisterView' }">{{$t('menu_register')}}</router-link>
            <router-link class="button button_light" :to="{ name:'LoginView' }">{{$t('menu_login')}}</router-link>
          </div>
        </div>
        <div class="col-6 col-md-6">
          <img src="../../assets/hand.png"  class="full_image">
          <img src="../../assets/hand-ltr.png"  class="full_image_ltr">
        </div>

      </div>
    </div>
  </section>

  <!-- about -->
  <section id="about" ref="section2"  class="py-5">
    <div class="container">
      <div class="row align-items-center justify-content-center my-5">
        
        <div class="col-md-12 mt-5" data-aos="fade-down">
          <h3 class="sub-headline-decor-small" >{{$t('text_about_us')}}</h3>
          <div class="row" >
            <div class="col-md-6">
              <div class="d-flex align-items-center">
                
                <div class="flex-grow-1">
                  <h4 class="sub-headline-decor">{{$t('text_our_journey_began_with_a_simple_idea')}}</h4>
                </div>
                <div class="flex-shrink-0">
                  <img src="../../assets/redux1.png">
                </div>

              </div>
            </div>
          </div>
          <p class="description mt-3" v-html="$t('text_home_page_about_desc')"></p>
          <div class="learnmore nav-item mb-2">
          <router-link :to="{ name : 'AboutUsPage' }" class="d-flex nav-link p-0"> <p v-html="$t('learmore')"> </p> <i class="bi bi-arrow-right"></i></router-link>
        </div>
        </div>
        
      </div>
    </div>
  </section>

  <section  data-aos="fade-down" data-aos-delay="200">
    <div class="container ">
      <img src="../../assets/bg-section.png" width="100%">
    </div>
  </section>

  
  <section class="py-5 get_started" ref="section3" data-aos="fade-down">
    <div class="container py-5">
      <div class="row align-items-center justify-content-center ">
        <div class="col-md-6 order-1 order-md-1" data-aos="fade-down" data-aos-delay="500">
          <img src="../../assets/building.png" class="w-100">
        </div>
        <div class="col-md-6 order-2 order-md-2" data-aos="fade-down" data-aos-delay="1000">
          <h4 class="sub-headline-decor text-black fw-bolder "><span v-html="$t('headline_get_started')"></span></h4>
          <div v-html="$t('description_privacy_policy')"></div>
        </div>
        

      </div>
    </div>
  </section>


  <section id="our_vision" ref="section3" data-aos="fade-down">
    <div class="container py-5">
      <div class="row align-items-center justify-content-center ">        
        <div class="col-md-6 order-md-2" data-aos="fade-down" data-aos-delay="500">
          <img src="../../assets/hand-2.png" class="w-100">
        </div>
        <div class="col-md-6 order-md-1" data-aos="fade-down" data-aos-delay="1000">
          <h4 class="sub-headline-decor fw-bolder text-white" v-html="$t('headline_our_vision')"></h4>
          <p class="description mt-3 text-light">
            {{ $t('description_our_vision') }}
          </p>
        </div>
      </div>
    </div>
  </section>

  <section id="our-vision" data-aos="fade-down" data-aos-delay="500">
    <div class="container py-5">
      
      <div class="row align-items-center justify-content-center py-5">
        <div class="col-12">
          <h4 class="sub-headline-decor text-black fw-bolder text-center mb-0 mb-md-5 pb-md-5">{{$t('headline_our_values')}}</h4><br><br>
          <div class="row g-4">
            <div class="col-12 col-md-6 col-lg-3">
              <div class="card custom_card text-center border-0 h-100">
                <div class="card-header border-0 bg-transparent"><img class="image-top- w-50" src="../../assets/Innovation.png" width="img-fluid"></div>
                <div class="card-body border-0">
                  <h4 class="sub-headline-decor-small text-white">{{$t('sub_headline_our_values_innovation')}}</h4>
                  <p class="description text-white">
                    {{$t('description_our_values_innovation')}}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3" >
              <div class="card custom_card text-center border-0 h-100">
                <div class="card-header border-0 bg-transparent"><img class="image-top- w-50" src="../../assets/Ethics.png" width="img-fluid"></div>
                <div class="card-body border-0">
                  <h4 class="sub-headline-decor-small text-white">{{$t('sub_headline_our_values_ethics')}}</h4>
                  <p class="description text-white">
                    {{$t('description_our_values_ethics')}}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3" >
              <div class="card custom_card text-center border-0 h-100">
                <div class="card-header border-0 bg-transparent"><img class="image-top- w-50" src="../../assets/Accessibility.png" width="img-fluid"></div>
                <div class="card-body border-0">
                  <h4 class="sub-headline-decor-small text-white">{{$t('sub_headline_our_values_accessibility')}}</h4>
                  <p class="description text-white">
                    {{$t('description_our_values_accessibility')}}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3" >
              <div class="card custom_card text-center border-0 h-100">
                <div class="card-header border-0 bg-transparent"><img class="image-top- w-50" src="../../assets/Continuous-Learning.png" width="img-fluid"></div>
                <div class="card-body border-0">
                  <h4 class="sub-headline-decor-small text-white">{{$t('sub_headline_our_values_continuous_learning')}}</h4>
                  <p class="description text-white">
                    {{$t('description_our_values_continuous_learning')}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="get_started py-5">
    <div class="container py-5" data-aos="fade-down" data-aos-delay="500">
      <div class="row align-items-center justify-content-center">
        <div class="col-12">
          <h4 class="sub-headline-decor text-black fw-bolder text-center mb-5">{{$t('headline_what_sets_us_apart')}}</h4>
          <p class="description text-center">
            {{$t('description_what_sets_us_apart')}}
          </p>
        </div>
      </div>
    </div>
  </section>

  <section data-aos="fade-down" data-aos-delay="200">
    <div class="container">
      <img class="w-100" src="../../assets/bg-section-4.png">
    </div>
  </section>
  
</template>

<script>
// @ is an alias to /src
import { useHead } from "@vueuse/head";
import SearchForGuest from "@/components/SearchForGuest.vue"

export default {
  name: 'HomeView',
  setup()
  {
    useHead({
        title: 'Welcome to Dubai GPT'
    })
  },
  
  components:{
    SearchForGuest
  }
}
</script>
<style>
.learnmore{
  color: #DC962D;
  font-family: Outfit;
  font-size: 28px;
  color: #3FC7CC;
}
</style>