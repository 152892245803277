<template>
    <div v-if="$store.getters.getLanguage == 'en'">
        <TermsInEnglish />
    </div>
    <div v-if="$store.getters.getLanguage == 'ar'">
        <TermsInArabic />
    </div>
</template>
<script>
import TermsInEnglish from "@/views/landing/TermsAndConditions_en.vue";
import TermsInArabic from "@/views/landing/TermsAndConditions_ar.vue";

export default{
    name: "TermsAndConditionsScreen",
    components:{
        TermsInEnglish,
        TermsInArabic
    }
}
</script>

<style>
dl dt 
{
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
}
dl dd
{
    font-size: 15px;
    margin-bottom: 10px;
    margin-left: 20px;
}
ul, ol
{
    margin-left: 20px;
}
</style>