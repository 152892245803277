<template>
  <div class="bg-transition-area">
    <div class="container">
      <div class="row align-items-center justify-content-center vh-100">
        <div class="col-md-12 col-lg-6 d-none d-lg-block">
          <img src="../../assets/infograph-1.png" class="w-100 pt-5 pt-md-0">
        </div>
        <div class="col-md">
          <form autocomplete="off" autosave="off" @submit.prevent="loginAction()">
          <div class="card h-100 p-2 p-md-5 auth_card border-0 shadow-lg">
            <div class="card-header bg-transparent border-0 text-center">
              <router-link class="text-decoration-none text-secondary" :to="{ name:'home' }">
                <div v-if="$store.getters.getThemeMode == 'light'">
                    <img src="../../assets/logo.png" alt="..." class="rounded" height="100">
                </div>
                <div v-else>
                    <img src="../../assets/logo-light.png" alt="..." class="rounded" height="100">
                </div>
                <!-- <img width="80" class="me-3" src="../../assets/logo.png"> -->
              </router-link>
              <h3 class="h4 mt-3 text-uppercase">Forgot your password?</h3>
              <p>Please follow the steps to recover it</p>
            </div>

            <div class="card-body text-start">
              
                <div class="mb-3">
                  <label for="username" class="form-label">Email*</label>
                  <input type="email" class="form-control form-control-lg " id="username" placeholder="" v-model="loginUser.email">
                  <span class="text-danger w-100 text-end" v-if="loginUser.login_error && v$.loginUser.email.$error"> {{ v$.loginUser.email.$errors[0].$message }} </span>
                  <p class="alert alert-info mt-4" v-if="message != ''">
                    {{ message }}
                  </p>
                </div>
                
                
            </div>
            <div class="card-footer bg-white border-0 bg-transparent">
              <div class="d-grid gap-3 text-center" v-if="!loading">
                  <button class="theme-button" type="submit">Recover Now</button>
                  <!-- <button class="theme-button-outline" type="button"><img src="../../assets/google.svg" height="20"> Sign in with Google</button> -->
              </div>
              <div class="d-grid gap-3 text-center" v-else>
                  <button disabled class="theme-button disabled" type="button">Please wait</button>
              </div>
              <p class="text-center mt-3">Already have an account? <router-link class="text-dark text-decoration-none fw-bold" :to="{ name:'LoginView' }">Sign In</router-link> or <router-link class="text-dark text-decoration-none fw-bold" :to="{ name:'RegisterView' }">Sign Up</router-link></p>

            </div>
            
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { mapState } from 'vuex';
import { useHead } from "@vueuse/head";
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import axios from 'axios';


export default{
  name : "ForgetPasswordView",

  data()
  {
    return {
      loading:false,
      loginUser : {
        email: '',
        login_error: false,
      },
      message:""
    }
  },
  setup() {      
    useHead({
        title: 'Login - Dubai GPT'
    })

    return {
      v$: useVuelidate(),
    }
  },  
  validations() {
    return {
      loginUser: {
        email: {
          required, email
        }
      },
    }
  },
  methods:{
    async loginAction()
    {
      this.v$.loginUser.$touch();
      this.loading = true;
      if (!this.v$.loginUser.$invalid)
      {
        this.loginUser.login_error = false;
        console.log("validated");
        

        // calling api
        const API_URL = `${process.env.VUE_APP_ROOT_API_ENDPOINT}/user/forgot-password/`;
        const formData = axios.toFormData(this.loginUser)

        axios.post(API_URL, formData)
        .then((response) => {
          if(response.status == 203)
          {
            this.$toast.error(response.data.message, {
              icon: "dangerous",
            }); 
            
          }
          else if(response.status == 201)
          {
            this.$toast.success(response.data.message, {
              icon: "check",
            });   
            this.message = `We've dispatched a temporary password to your email address (${this.loginUser.email}). Please log in to your email and review the password information in order to make alterations.`
          }
          this.loading = false;
        })
        // calling api


        .catch((error) => {
          // Handle errors
          console.log(error);
          this.$toast.error("Login failed, an unexpected error occured. Please try again later");
          this.loading = false;
        });
       
      }
      else
      {
        this.loading = false;
        console.log("not done");
        this.$toast.error("Please fill the required fields", {
            icon: "dangerous",
        });
        console.log(this.v$.loginUser.$errors)
        this.loginUser.login_error = true;
      } 
    }
  },
  created()
  {
    this.$store.dispatch('logout')
  }
}
</script>