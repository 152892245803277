<template>
    <section class="card h-100 p-0">
        <div class="card-header bg-transparent border-0 px-0 shadow">
            <div class="container">
                <nav class="d-flex gap-2 overflow-x-auto w-100">
                    <router-link class="button button_sm" :to="{ name:'search', query: { q: $route.query.q } }">All</router-link>
                    <router-link class="button button_sm" :to="{ name:'SearchWebComponent', query: { q: $route.query.q } }">Web</router-link>
                    <router-link class="button button_sm" :to="{ name:'SearchImageComponent', query: { q: $route.query.q } }">Image</router-link>
                    <router-link class="button button_sm" :to="{ name:'SearchVideoComponent', query: { q: $route.query.q } }">Video</router-link>
                    <router-link class="button button_sm" :to="{ name:'SearchNewsComponent', query: { q: $route.query.q } }">News</router-link>
                    <router-link class="button button_sm" :to="{ name:'SearchGPTComponent', query: { q: $route.query.q } }">GPT</router-link>

                </nav>
                <p class="text-secondary fw-light m-0 p-0 text-start">Available data based on <span class="fw-bold">{{ $route.query.q }}</span></p>
            </div>
        </div>
        <div class="card-body overflow-auto p-0">
            <div class="container my-3">
                <div class="row align-items-center justify-content-center my-5 py-5">
                    <div class="col-md-4 text-center">
                        <img src="../../../assets/logo.png" alt="..." class="rounded" height="150">
                        <h5 class="text-secondary my-4">How can I help you today?</h5>
                        <p>This feature is coming soon</p>
                        <!-- <center>
                            <button class="button border-0">Coming soon</button>
                        </center> -->
                    </div>
                </div>
                
                <div class="row d-none">
                    <div class="col-12">
                        <div class="d-flex align-items-top">
                            <div class="p-2">
                                <img src="../../../assets/logo.png" alt="..." class="rounded" height="50">
                            </div>
                            <div class="flex-grow-1">
                                <blockquote class="text-secondary shadow-sm p-2 rounded h-100">
                                    <div class="flex-grow-1 text-secondary" v-html="streamedText.replace(/\n/g, '<br />')" v-if="streamedText != ''"></div> 
                                    <div v-else>
                                        <div v-if="gpt_loading">
                                            <Skeleton class="mb-2"></Skeleton>
                                            <Skeleton width="10rem" class="mb-2"></Skeleton>
                                            <Skeleton width="5rem" class="mb-2"></Skeleton>
                                            <br>
                                            <Skeleton class="mb-2"></Skeleton>
                                            <Skeleton class="mb-2"></Skeleton>
                                            <br>
                                            <Skeleton class="mb-2"></Skeleton>
                                            <Skeleton class="mb-2"></Skeleton>
                                        </div>
                                    </div>
                                </blockquote>
                                <div>
                                <button v-if="gpt_task" class="button button_sm button_light mt-3" @click="fetchStreamedText()"><i class="bi bi-arrow-clockwise"></i> Regenerate</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
        <div class="card-footer d-flex justify-content-center bg-transparent">
            <div class="searchboxForm">
                <form class="d-flex gap-1 align-items-center" id="searchBox" @submit.prevent="searching()">
                    <div class="d-flex w-100 bg-transparent rounded">
                        <input :disabled="!gpt_task" type="search" autocomplete="off" @keyup="getKeywordSuggestions()" autofocus required name="q" list="suggetions" class="searchInput form-control form-control-lg border"  :class="{ active: question_form.question, 'text-danger': hasError }" :placeholder="$t('placeholder_serach')" aria-label="With textarea" v-model="question_form.question">
                        <datalist id="suggetions"><option :value="suggestion" v-for="suggestion in search_keyword_suggesion" :key="suggestion">{{suggestion}}</option></datalist>
                    </div>
                    <div class="d-flex flex-row justify-content-center align-items-center gap-2">
                        <button type="button" v-if="isMicAvailable" class="btn btn-outline-default waves searchButton" :class="{ active: isListening }" @click="toggleRecognition" :title="isListening ? 'Stop Listening' : 'Start Listening'">
                            <i class="bi bi-mic-fill h3 icon"></i>
                        </button>
                        <button class="btn btn-outline-default searchButton" type="submit" :disabled="!gpt_task">
                            <i class="bi bi-send-fill h3"></i>
                        </button>
                    </div>
                </form>
            </div>
            
        </div>
    </section>
    
</template>

<script>
import { useHead } from "@vueuse/head";
import { useRoute } from 'vue-router';
import axios from 'axios';
import Skeleton from 'primevue/skeleton';
import { containsBadWord } from '../../../badWords';

export default {
    name : "SelectGPT",
    components:{
    Skeleton,
    },
    data() {
    return {
        conversation_id : this.$store.getters.conversationId,
        conversation_data : '',
        search_engine_response: null,
        search_keyword_suggesion: [],
        question_form : {
            question : ""
        },
        gpt_loading: false,

        isListening: false,
        recognition: null,
        isMicAvailable : true,

        streamedText:'',
        gpt_task: false
    }
    },
    created()
    {
        // this.getConversations()
        this.get_search_keyword();
        //this.searching();   
    },
    methods: {
        get_search_keyword()
        {
            const route = useRoute()
            this.question_form.question = route.query.q
        },

        async fetchStreamedText() {
            this.streamedText = "";
            this.gpt_loading = true
            this.gpt_task = false
            const streamUrl = `${process.env.VUE_APP_ROOT_API_ENDPOINT}/gpt/campaign?prompt=${this.question_form.question}`;
            
            await fetch(streamUrl).then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                this.gpt_loading = false
                const reader = response.body.getReader();
                const streamDecoder = new TextDecoder();
                const processStream = ({ done, value }) => {
                    if (done) {
                        console.log('Stream complete');
                        this.gpt_task = true;
                        return;
                    }
                    const chunk = streamDecoder.decode(value, { stream: true });
                    this.streamedText += chunk;
                    return reader.read().then(processStream);
                };
                return reader.read().then(processStream);
            }).catch(error => {
                    console.error('Fetch failed:', error);
            });
        },
        
        removeCharacter(data) {
            try
            {
                if(data != "")
                {
                    return data.replace("?", "");
                }
                else
                {
                    return data
                } 
            }
            catch (error)
            {
                console.error(error);
                // Expected output: ReferenceError: nonExistentFunction is not defined
                // (Note: the exact output may be browser-dependent)
                return data
            }
        },

        badWordFilter()
        {
            return containsBadWord(this.question_form.question);
        },

        toggleRecognition()
        {
            this.checkMicAvailability()
            if (this.isMicAvailable)
            {
                if (this.isListening)
                {
                    this.recognition.stop();
                }
                else
                {
                    this.recognition.start();
                }
            }
            else
            {
                this.$toast.error("Mic is not available", {
                    icon: "dangerous",
                }); 
            }            
        },

        checkForCommand(result)
        {
            const t = result.transcript;
            if (t.includes('stop'))
            {
                this.recognition.stop();
            }
        },

        
        checkMicAvailability()
        {
            navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
            // Code for success
                console.log(stream)
                this.isMicAvailable = true
                this.checkMicAvailability()
            }).catch(err => {
                console.log(err)
                this.isMicAvailable = false
            })
        },

        async searching()
        {
            if(this.question_form.question != "")
            {
                this.$router.push({path: this.$route.fullPath, query: {q: this.removeCharacter(this.question_form.question)} });

                // this.gpt_loading = true;

                let wordcheker = containsBadWord(this.question_form.question)
                if(!wordcheker)
                {
                    await this.fetchStreamedText(this.question_form.question);
                }
                else
                {
                    // alert(`Please avoid using offensive or inappropriate language (${wordcheker}) as it's against Dubai's government rules.`);
                    this.$toast.error(`Please avoid using offensive or inappropriate language (${wordcheker}) as it's against Dubai's government rules.`); 
                }
            }
            else
            {
                this.$toast.error("Questions are required"); 
            }
        },


        getKeywordSuggestions()
        {
            if(this.question_form.question != "")
            {
                const API_URL = `${process.env.VUE_APP_ROOT_API_ENDPOINT}/user/search/suggestions/${this.question_form.question}/`;
                const headers = { 
                    "Authorization": `Bearer ${this.$store.getters.getToken}`,
                };

                axios.get(API_URL, {headers})
                .then((response) => {
                    if(response.status == 204)
                    {
                        // this.$toast.error(response.data.message, {
                        //     icon: "dangerous",
                        // }); 
                    }
                    else if(response.status == 200)
                    {
                        this.search_keyword_suggesion = response.data.suggestion
                    }

                })
                .catch(() => {
                    this.search_engine_loading = false;
                });
            }
        }
    },
    setup()
    {
        useHead({
            title: 'GPT Conversation - Dubai GPT'
        })
    },
    mounted()
    {
        // this.searching()
        // to use mic
        this.recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
        this.recognition.continuous = true;
        this.recognition.interimResults = true;

        this.recognition.onstart = () => {
            console.log('Recognition Started');
            this.isListening = true;
        };

        this.recognition.onend = () => {
            console.log('Recognition Stopped');
            this.isListening = false;
        };

        this.recognition.onresult = (evt) => {
            let finalTranscript = '';
            for (let i = 0; i < evt.results.length; i++) {
                const result = evt.results[i];
                if (result.isFinal) {
                finalTranscript += result[0].transcript + ' ';
                this.checkForCommand(result[0]);
                }
            }
            this.question_form.question = finalTranscript.trim();
        };
        // to use mic
    },  
}
</script>