<template>
  <div class="bg-transition-area">
    <div class="container">
      <div class="row align-items-center justify-content-center vh-100">
        <div class="col-md-12 col-lg-6 d-none d-lg-block">
          <img src="../../assets/infograph-1.png" class="w-100 pt-5 pt-md-0">
        </div>
        <div class="col-md">
          <form autocomplete="off" autosave="off" @submit.prevent="loginAction()">
          <div class="card h-100 p-2 p-md-5 auth_card border-0 shadow-lg">
            <div class="card-header bg-transparent border-0 text-center">
              <router-link class="text-decoration-none text-secondary" :to="{ name:'home' }">
                <div v-if="$store.getters.getThemeMode == 'light'">
                    <img src="../../assets/logo.png" alt="..." class="rounded" height="100">
                </div>
                <div v-else>
                    <img src="../../assets/logo-light.png" alt="..." class="rounded" height="100">
                </div>
                <!-- <img width="80" class="me-3" src="../../assets/logo.png"> -->
              </router-link>
              <h3 class="h4 mt-3">WELCOME BACK</h3>
            </div>

            <div class="card-body text-start">
                <!-- <span v-if="!$v.password.minLength">Username must be at least 3 characters long</span> -->
                <div class="mb-3">
                  <label for="username" class="form-label">Username / Email*</label>
                  <input type="email" class="form-control form-control-lg " id="username" placeholder="" v-model="loginUser.email">
                  <span class="text-danger w-100 text-end" v-if="loginUser.login_error && v$.loginUser.email.$error"> 
                    <span v-if="v$.loginUser.email.$errors[0].$message == 'Value is required'">Email is required</span>
                    <span v-if="v$.loginUser.email.$errors[0].$message == 'Value is not a valid email address'">Please enter a valid email address</span>
                  </span>
                </div>
                <div class="mb-3">
                  <label for="password" class="form-label">Password*</label>
                  <input type="password" class="form-control form-control-lg" id="password" placeholder="" v-model="loginUser.password">
                  <span class="text-danger w-100 text-end" v-if="loginUser.login_error && v$.loginUser.password.$error">
                    <!-- {{ v$.loginUser.password.$errors[0].$message }} -->
                    <span v-if="v$.loginUser.password.$errors[0].$message == 'Value is required'">Password is required</span>
                  </span>
                </div>
                <div class="row">
                  <div class="col-6 ">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="gridCheck" v-model="loginUser.remember_me">
                      <label class="form-check-label" for="gridCheck">
                        Remember me
                      </label>
                    </div>
                  </div>
                  <div class="col-6 text-end">
                    <router-link class="text-link text-decoration-none" :to="{ name:'ForgotPasswordView' }">Forgot password</router-link>
                  </div>
                </div>
                
            </div>
            <div class="card-footer bg-white border-0 bg-transparent">
              <div class="d-grid gap-3 text-center" v-if="!loading">
                  <button class="theme-button" type="submit">Sign in</button>
                  <!-- <button class="theme-button-outline" type="button"><img src="../../assets/google.svg" height="20"> Sign in with Google</button> -->
                  <GoogleAuth></GoogleAuth>
              </div>
              <div class="d-grid gap-3 text-center" v-else>
                  <button disabled class="theme-button disabled" type="button">Please wait</button>
                  <GoogleAuth></GoogleAuth>
              </div>

              <p class="text-center mt-3">Don't have an account? <router-link class="text-dark text-decoration-none fw-bold" :to="{ name:'RegisterView' }">Sign Up</router-link></p>

            </div>
            
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { mapState } from 'vuex';
import { useHead } from "@vueuse/head";
import useVuelidate from '@vuelidate/core';
import { required, minLength, email } from '@vuelidate/validators';
import axios from 'axios';

import GoogleAuth from "@/components/GoogleAuth.vue";

export default{
  name : "LoginView",
  components:{
    GoogleAuth
  },
  data()
  {
    return {
      loading:false,
      loginUser : {
        email: '',
        password: '',
        remember_me: false,
        login_error: false,
      }
    }
  },
  setup() {      
    useHead({
        title: 'Login - Dubai GPT'
    })

    return {
      v$: useVuelidate(),
    }
  },  
  validations() {
    return {
      loginUser: {
        email: {
          required, email
        },
        password: { 
          required,
          minLength: minLength(6),
        }
      },
    }
  },
  methods:{
    async login() {
      this.v$.loginUser.$touch();
      if (!this.v$.loginUser.$invalid)
      {
        this.loginUser.login_error = false;
        console.log("validated");
        this.$store.dispatch('login', this.loginUser)
        .then(() => {
          // Redirect to a secure page or perform other actions on successful login
          if(this.$store.getters.isAuthenticated == true)
          {
            this.$toast.success("Login success", {
              icon: "check",
            }); 
            this.$router.push({ name: 'DashboardScreen' })
          }
        })
        .catch(() => {
          // Handle login error
          this.$toast.error("Login failed, an unexpected error occured. Please try again later"); 
        });
      }
      else
      {
        console.log("not done");
        this.$toast.error("Please fill the required fields", {
            icon: "dangerous",
        });
        console.log(this.v$.loginUser.$errors)
        this.loginUser.login_error = true;
      }
    },
    async loginAction()
    {
      this.v$.loginUser.$touch();
      this.loading = true;
      if (!this.v$.loginUser.$invalid)
      {
        this.loginUser.login_error = false;
        console.log("validated");
        

        // calling api
        const API_URL = `${process.env.VUE_APP_ROOT_API_ENDPOINT}/user/login/`;
        const formData = axios.toFormData(this.loginUser)

        axios.post(API_URL, formData)
        .then((response) => {
          if(response.status == 201)
          {
            this.$toast.success(response.data.message, {
              icon: "check",
            }); 
            this.$store.commit('SET_USER', response.data.data.user)
            this.$store.commit('SET_TOKEN', response.data.data.token)
            this.$store.commit('SET_AUTHENTICATED', true)
            this.$store.commit('SET_USER_AVATAR', response.data.data.user.avatar)
            this.loading = false;
            this.$router.push({ name: 'DashboardScreen' })            
          }
          else
          {
            this.loading = false;
            this.$toast.error(response.data.message, {
              icon: "dangerous",
            });             
          }
          
        })
        // calling api


        .catch((error) => {
          // Handle errors
          console.log(error);
          this.$toast.error("Login failed, an unexpected error occured. Please try again later");
          this.loading = false;
        });
       
      }
      else
      {
        this.loading = false;
        console.log("not done");
        this.$toast.error("Please fill the required fields", {
            icon: "dangerous",
        });
        console.log(this.v$.loginUser.$errors)
        this.loginUser.login_error = true;
      } 
    }
  },
  created()
  {
    this.$store.dispatch('logout')
  }
}
</script>