<template>
    <!-- about -->
    <section id="aboutuspaage" ref="section2" class="py-5">
        <div class="container py-1">
            <div class="row align-items-center justify-content-center ">

                <div class="col-md-12">

                    <div class="row">
                        <div class="col-md-6">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1">
                                    <h3 class="sub-headline-decor-small">منشأنا: رؤية للتغيير </h3>
                                </div>
                                <div class="flex-shrink-0">
                                    <img src="../../assets/redux1.png">
                                </div>

                            </div>
                        </div>
                    </div>
                    <p class="description mt-3">في DubaiGPT، بدأت قصتنا برؤية فريدة: ثورة في الطريقة التي يتفاعل بها
                        البشر مع التكنولوجيا. متجذرة في شغف عميق بالابتكار، انطلقنا لنجسر الفجوة بين التعبير البشري وفهم
                        الآلة. لم يكن هدفنا فقط إنشاء منتج، بل كان لدينا رغبة في أن نحفر طريقًا جديدًا في التواصل، حيث
                        يعزز الذكاء الاصطناعي ويثري تجربة الإنسان.</p>
                </div>

            </div>
            <div class="row ">

                <div class="col-md-12">
                    <div class="mt-2 py-5">
                        <div class="flex-grow-1">
                            <h3 class="sub-headline-decor-small">رحلتنا: من الفكرة إلى الواقع</h3>
                        </div>
                        <p class="description mt-3">كانت رحلة DubaiGPT واحدة من السعي اللاهوتي والتفاني. فريقنا، مجموعة
                            متنوعة من الخبراء في الذكاء الاصطناعي ومعالجة اللغة الطبيعية وتصميم تجربة المستخدم، كان حجر
                            الزاوية لنجاحنا. سويًا، حولنا فكرة بسيطة إلى شريك في الحوار ديناميكي وذكي يعيد تعريف حدود
                            الحوار.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row dubaivision">
                <div class="aboutusVision col-sm-3">

                </div>
                <div class="col-sm-9">
                    <div class="mt-5 py-5">
                        <div class="flex-grow-1">
                            <h3 class="sub-headline-decor-small">منشأنا: رؤية للتغيير</h3>
                        </div>
                        <p class="description mt-3">في DubaiGPT، بدأت قصتنا برؤية فريدة: ثورة في الطريقة التي يتفاعل بها
                            البشر مع التكنولوجيا. متجذرة في شغف عميق بالابتكار، انطلقنا لنجسر الفجوة بين التعبير البشري
                            وفهم الآلة. لم يكن هدفنا فقط إنشاء منتج، بل كان لدينا رغبة في أن نحفر طريقًا جديدًا في
                            التواصل، حيث يعزز الذكاء الاصطناعي ويثري تجربة الإنسان.</p>
                    </div>
                </div>
            </div>
            <div class="row dubaivision">
                <div class="col-sm-9">
                    <div class="mt-5 py-5">
                        <div class="flex-grow-1">
                            <h3 class="sub-headline-decor-small">رحلتنا: من الفكرة إلى الواقع</h3>
                        </div>
                        <p class="description mt-3">كانت رحلة DubaiGPT واحدة من السعي اللاهوتي والتفاني. فريقنا، مجموعة
                            متنوعة من الخبراء في الذكاء الاصطناعي ومعالجة اللغة الطبيعية وتصميم تجربة المستخدم، كان حجر
                            الزاوية لنجاحنا. سويًا، حولنا فكرة بسيطة إلى شريك في الحوار ديناميكي وذكي يعيد تعريف حدود
                            الحوار.</p>
                    </div>
                </div>
                <div class="aboutusVisiontwo col-sm-3">

                </div>
            </div>
        </div>
        <div class="container">
            <div class="row ">

                <div class="col-md-12">
                    <div class="mt-3 py-3">
                        <div class="flex-grow-1">
                            <h3 class="sub-headline-decor-small">فلسفتنا: الابتكار والتعاون والإمكانيات اللامحدودة</h3>
                        </div>
                        <p class="description mt-3">في قلب DubaiGPT تكمن فلسفة الابتكار المستمر والتعاون. نحن نؤمن بدفع
                            حدود الإمكان، واستكشاف آفاق جديدة، والتطور المستمر لتلبية احتياجات مستخدمينا المتغيرة.
                            منصتنا ليست مجرد أداة؛ بل هي شاهد على قوة الخيال الجماعي والإمكانيات اللانهائية التي تنشأ
                            عندما يتلاقى التكنولوجيا والإبداع.</p>
                    </div>
                    <div class="px-3 flex-grow-1">
                        <h4 class="sub-headline-decor-small">مهمتنا: تمكين التواصل</h4>
                        <p class="description mt-3">مهمتنا في DubaiGPT هي تمكين الأفراد والمؤسسات بمستوى جديد من
                            التواصل. من خلال استغلال قوة الذكاء الاصطناعي، نهدف إلى خلق عالم حيث لا يفهم التكنولوجيا
                            فقط، بل تعزز أيضًا التعبير البشري. نحن ملتزمون بتقديم شريك في الحوار ذكي يكون بديهيًا
                            ومتجاوبًا وعميق الاستشعار للدقائق في اللغة البشرية.</p>
                    </div>
                </div>
            </div>

            <div class="row ">
                <div class="col-md-12">
                    <div class="mt-3 py-3">
                        <div class="flex-grow-1">
                            <h3 class="sub-headline-decor-small">مستقبلنا: صياغة العصر التالي للتفاعل</h3>
                        </div>
                        <p class="description mt-3">نحن في DubaiGPT على استعداد لقيادة العصر التالي للتفاعل بين البشر
                            والآلات. نحن نستكشف باستمرار مستندات جديدة في مجال الذكاء الاصطناعي ومعالجة اللغة الطبيعية،
                            وندفع بيان الجدارة فيما هو ممكن، ونسعى لخلق مستقبل يكون فيه التكنولوجيا والإنسان متداخلين
                            بسلاسة.</p>
                    </div>
                </div>

            </div>
            <div class="container">
                <div class="row ">
                    <div class="col-md-12">
                        <div class="mt-3 py-5">
                            <div class="flex-grow-1">
                                <h3 class="sub-headline-decor-small">انضم إلينا في رحلتنا</h3>
                            </div>
                            <p class="description mt-3">ندعوك للانضمام إلينا في هذه الرحلة المثيرة بينما نواصل استكشاف
                                الإمكانيات اللامحدودة للتواصل المدعوم بالذكاء الاصطناعي. مع DubaiGPT، ليس مستقبل الحوار
                                مجرد حلم؛ بل هو واقع نحن نبنيه معًا.</p>
                        </div>
                        <div class="px-3 flex-grow-1">
                            <h4 class="sub-headline-decor-small">. ما يفصلنا:</h4>
                            <p class="description mt-3">التفاني القوي في الجودة والموثوقية؛</p>
                            <p class="description mt-3">ينعكس التزامنا بالتميز في الموثوقية والعظمة لمنصتنا التي تعمل
                                بالذكاء الاصطناعي في DubaiGPT. تدرك منظمتنا أهمية ثقة المستخدم في التكنولوجيا وتبذل كل
                                جهد ممكن لضمان أن DubaiGPT تقدم تفاعلات دقيقة وفهماً واعياً وذو مغزى. يضمن أداءنا
                                الاستثنائي إجراءات التدريب الصارمة لدينا وتحسيننا المستمر لنموذج الذكاء الاصطناعي لدينا.
                                هذا ما يميزنا في الصناعة.</p>
                        </div>
                        <div class="px-3 flex-grow-1">
                            <h4 class="sub-headline-decor-small">تصميم مركز على المستخدم ل</h4>
                            <p class="description mt-3"> لفوائد العملية؛</p>
                            <p class="description mt-3">تقنيتنا في التصميم تركز على المستخدم. نحن نؤمن بأن التكنولوجيا
                                يجب أن تتناغم مع متطلبات الإنسان بدلاً من العكس. تم تطوير DubaiGPT باستيعاب فهم عميق
                                لاحتياجات ومطالب جمهورنا المتنوع. منصتنا مصممة بشكل خاص لتلبية متطلبات متنوعة من الأفراد
                            </p>
                            <p class="description mt-3">يستكشفون تفاصيل اللغة، والمعلمين السعي لتحسين تجربة التعلم،
                                والمهنيين يغوصون في عالم اللغة.</p>
                        </div>
                        <div class="px-3 flex-grow-1">
                            <h4 class="sub-headline-decor-small">إطار ذكاء اصطناعي كامل مرن وشامل:</h4>
                            <p class="description mt-3">تتميز DubaiGPT بمرونة نموذجها للذكاء الاصطناعي. نظرًا لتدريبها
                                على مجموعة بيانات شاسعة ومتنوعة، تستطيع منصتنا المشاركة في مناقشات حول مجموعة كبيرة من
                                المواضيع. DubaiGPT بارعة في التعامل مع تفاصيل ودقائق اللغة البشرية، مشمولة بين الحوار
                                الفني والسردي الخيالي. تضمن الشمولية أن جميع المستخدمين، بغض النظر عن خبرتهم أو مجالات
                                اهتمامهم، قادرون على الاستفادة بالكامل من قدرات التواصل المدفوع بالذكاء الاصطناعي.</p>
                        </div>
                        <div class="px-3 flex-grow-1">
                            <h4 class="sub-headline-decor-small">التطبيقات العملية:</h4>
                            <p class="description mt-3">تتجاوز مجالاتنا القدرات الفكرية للذكاء الاصطناعي. منظمتنا ملتزمة
                                بتعزيز الاتصال بين الإنسانية والتكنولوجيا من خلال دمج وظائف DubaiGPT في سيناريوهات
                                استخدام عملية. بعيدًا عن كونها مجرد أداة حوار، فإن منصتنا تمتلك الإمكانية لإثراء عمليات
                                اتخاذ القرار، وزيادة الكفاءة التشغيلية، وتعزيز الابتكار في مجموعة متنوعة من المجالات.
                                منظمتنا ملتزمة بتوفير وسائل لمستخدمينا لاستخدام الذكاء الاصطناعي في كل من مجالاتهم
                                الشخصية والمهنية.</p>
                        </div>
                        <div class="px-3 flex-grow-1">
                            <h4 class="sub-headline-decor-small">عامل التفريق في DubaiGPT:</h4>
                            <p class="description mt-3">تكمن تميزنا في مفهوم DubaiGPT كشريك متطور. نحن لا نقدم مجرد
                                خدمة؛ بل نعزز شراكة متطورة مع عملائنا. DubaiGPT هو حليف فريد وقيم في استكشاف الإمكانيات
                                للتواصل المدفوع بالذكاء الاصطناعي بفضل التفاني الراسخ للتميز، والتصميم المركز على
                                المستخدم، والتطبيقات العملية.</p>
                        </div>
                        <div class="px-3 flex-grow-1">
                            <h4 class="sub-headline-decor-small">3. DubaiGPT ترحب بك في مستقبل الاتصال
                                دعم التعاون والابتكار3.:</h4>
                            <p class="description mt-3">DubaiGPT هي منظمة تأسست على مبدأ تمكين المجتمعات والمنظمات
                                والشركات لاستخدام أداة مبتكرة تحول الطرق التي نتواصل بها ونفكر بشكل إبداعي ونحل المشاكل.
                                منظمتنا ملتزمة بتحسين إمكانية الوصول وسهولة الوصول وسهولة التفاعل مع التكنولوجيا حتى
                                تتمكن حقًا من ثورة حياتنا.</p>
                        </div>
                        <div class="px-3 flex-grow-1">
                            <h4 class="sub-headline-decor-small">استكشاف مسارات تعبير وتواصل جديدة</h4>
                            <p class="description mt-3">تتجاوز التفاني لدينا في تقديم خدمة؛ هدفنا هو تسهيل أشكال غير عادية من التواصل والتلاحم. يسهل DubaiGPT دمج الذكاء الآلي والحدس البشري، مما يخلق مجالًا واسعًا من الفرص حيث تعزز التكنولوجيا وتوسع قدرات الإنسان.</p>
                        </div>
                        <div class="px-3 py-4 flex-grow-1">
                            <h1 class="sub-headline-decor-small">شراكة نحو المستقبل</h1>
                            <p class="description mt-3">ابدأ معنا ونحن نغوص في عصر جديد من التواصل. بتوافق، يمكننا استكشاف الإمكانيات اللامحدودة</p>
                            <p class="description mt-3"> للتفاعل المدفوع بالذكاء الاصطناعي وإقامة مستقبل يمكننا فيه استخدام التكنولوجيا لإقامة اتصالات، وتوليد الأفكار، وحل التحديات بطرق لم يسبق لها مثيل.</p>
                            <p class="description mt-3">مرحبًا بك في DubaiGPT، منظمة تشكل بالتعاون مستقبل الاتصال من رؤية خاملة إلى واقع محقق.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "AboutUs"
}
</script>

<style>
dl dt {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
}

dl dd {
    font-size: 15px;
    margin-bottom: 10px;
    margin-left: 20px;
}

ul,
ol {
    margin-left: 20px;
}

.aboutusVision {
    background: url("../../assets/campLight.png") left top;
    background-size: contain;
    background-repeat: no-repeat;
}

.aboutusVisiontwo {
    background: url("../../assets/campLight.png") left top;
    background-size: contain;
    background-repeat: no-repeat;
    transform: scaleX(-1);
}

.dubaivision .aboutusVision {

    transform: scaleX(-1);
}

.dubaivision .aboutusVisiontwo {
    transform: scaleX(1);
}
</style>