<template>
    <section class="container">
        <div class="card border-0">
            <div class="card-header border-0 p-4">
                
                <h1 class="h4"><dt>Dubai GPT Privacy Policy</dt></h1>
                               
            </div>
            <div class="card-body p-4">
                <h1 class="h5">Effective Date: 1.1.24</h1>
                <hr>
                <div>
                    <dl>
                        <dd>Welcome to Dubai GPT! We respect your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, share, and protect information about our users and outlines your rights regarding your personal information.</dd>
                         <dt>1. Members' Terms and Privacy Statements</dt>
                        <dd>
                            <p>DUBAI GPT wishes a warm welcome ("we," "us," or "our"). Your privacy is extremely important to us, and we are dedicated to safeguarding your personal information. This Privacy Statement establishes our protocols with respect to the collection and safeguarding of your information during your use of our services. Through your use of or access to our services, you submit to the conditions and terms outlined in this privacy statement. Kindly study this policy in every detail to gain understanding of our approach to handling your information.</p>
                            
                        </dd>
                        <dt>2. By accepting the terms</dt>
                        <dd>
                            <p>You acknowledge that you have reviewed, understood, and agree to be bound by the terms and conditions marked in this Privacy Statement while using our services. You are responsible for checking this Privacy Statement, which is subject to change at any time. Your continued utilization of our services subsequently to any changes implies your agreement with the changed terms.
</p>
                        </dd>
                        <dt>3. Implementing Services</dt>
                        <dd>
                            <p>Our organization provides a wide range of services, which comprises software, websites, mobile applications, and various other digital platforms.</p>
                            <p>You agree, when you use our services, to:</p>
                            <ul>
                                <li>I will furnish precise and comprehensive information as per your request.</li>
                                <li>Ensure strict adherence to all relevant laws and regulations.</li>
                                <li>Please avoid from participating in any undertaking that has the potential to create problems, damage, cross-talk, or damage to our services, as well as the networks and systems that are linked to them.</li>
                                <li>Comply with the conditions of service or practices that are specific to the DUBAI GPT's offerings of particular services or features.</li>
                            </ul>
                        </dd>
                        <dt>4. Conduct by Users</dt>
                        <dd>
                            <p>You agree, in order to preserve a respectful and secure environment for all users, not to:</p>
                            <ul>
                                <li>Participate in any activities that are unlawful, fraudulent, or detrimental.</li>
                                <li>Issuing of information or distribution of any criminal, offensive, or rights-violating material.</li>
                                <li>Engaging in fraud or intentionally claiming affiliation with DUBAI GPT is strictly prohibited.</li>
                                <li>One potential threat is an attempt to illegally access our systems, data, or accounts.</li>
                                <li>Employ automated methods, such as scrapers or bots, to gain unauthorized access to our services.</li>
                                <li>One should not harass, stalk, or indulge in any other harmful conduct aimed on other users.</li>
                                <li>Unauthorized collection or storage of personal information belonging to other users.</li>
                            </ul>
                        </dd>
                        <dt>5. Intellectual Property (IP)</dt>
                        <dd>
                            <p>Intellectual property laws protect all materials and content on DUBAI GPT, including but not limited to text, illustrations, logos, images, software, and audiovisual material. Without our prior written consent or that of the necessary rights holders, you are not permitted to use, reproduce, distribute, modify, or create derivative works from our content.</p>
                            <p>You grant DUBAI GPT a worldwide, non-exclusive, royalty-free license to utilize, display, reproduce, and distribute the content you upload or submit to our services, while retaining ownership of the material.</p>
                        </dd>
                        <dt>6. Privacy is maintained</dt>
                        <dd>
                            <p>We place a premium on your privacy and are dedicated to safeguarding your personal information. We detail in our Privacy Statement how we acquire, utilize, divulge, and safeguard your information. You grant consent to our data practices as outlined in the Privacy Statement by utilizing our services.</p>
                            
                        </dd>
                       
                        <dt>7. A disclaimer</dt>
                        <dd>
                            <p>DUBAI GPT carefully aims to guarantee the uniformity and accessibility of our services. We do not, however, guarantee uninterrupted or error-free service. Without prior notice, we reserve the right to temporarily suspend or restrict access to our services for updates, maintenance, or other reasons.</p>
                            
                                <p><b>Third-Party Content:</b> Our services might include connections to websites, applications, or content belonging to third parties. DUBAI GPT is exempt from liability for the appropriateness, legality, or accuracy of the mentioned content. Regarding interactions with third-party content, the terms and policies of that third party apply.</p>
                               <p>Please note that although we make every effort to offer precise and current information, we cannot guarantee the accuracy, veracity, or dependability of the content available on DUBAI GPT. You hereby declare that any dependence on the provided information is entirely at your own peril.</p>
                               <p>We implement adequate security measures to safeguard both our services and your data. Nevertheless, the security of information stored on our systems or transmitted via the internet cannot be guaranteed. It is your duty to ensure the privacy of your login credentials and to notify us immediately of any unauthorized activity that may have occurred on your account.</p>
                            
                        </dd>
                        <dt>8. Restrictions on Liability</dt>
                        <dd>
                            <p>DUBAI GPT and its affiliates, directors, officers, employees, and agents shall not be liable, to the maximum extent permitted by applicable law, for any direct, indirect, incidental, special, consequential, or punitive damages that may arise from or in connection with your use of our services, even if we have been informed of the possibility of such damages. Our liability is restricted to the greatest degree allowed by law.</p>
                        </dd>
                        <dt>9. Indemnification</dt>
                        <dd>
                            <p>You hereby grant DUBAI GPT and its affiliates, directors, officers, employees, and agents the responsibility of covering and holding them harmless against any claims, liabilities, damages, losses, costs, and expenses (including attorney's fees) that may arise as a result of or in connection with your utilization of our services, breach of these terms, or infringement of our policies.</p>
                        </dd>
                        <dt>10. Any interests of a third party</dt>
                        <dd>
                            <ul>
                                <li>The governing law</li>
                            </ul>
                            <p>Without regard to its conflict of laws principles, this Privacy Statement and your use of DUBAI GPT services shall be governed by and construed in accordance with the laws of the United Arab Emirates. The sole jurisdiction over any disputes that may arise in relation to or under this Privacy Policy shall be the courts located in Dubai, United Arab Emirates.</p>

                        </dd>
                        <dt>11. Information Contact</dt>
                        <dd>
                            <p>Should you have any inquiries, concerns, or remarks concerning this Privacy Statement or our offerings, kindly reach out  <a class="text-secondary" style="text-decoration: none;" href="mailto:info@dubaigpt.com">info@dubaigpt.com</a>.</p>
                            <p>You agree to this Privacy Statement and confirm that you have read, understood, and agreed to it by utilizing the services of DUBAI GPT. We are committed to safeguarding your privacy and delivering a dependable and secure user experience, as we value your confidence.</p>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
        
    </section>
</template>
<script>
export default{
    name: "PrivacyPolicyScreen"
}
</script>

<style>
dl dt 
{
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
}
dl dd
{
    font-size: 15px;
    margin-bottom: 10px;
    margin-left: 20px;
}
ul, ol
{
    margin-left: 20px;
}
</style>