<template>
    <section class="container">
        <div class="card border-0">
            <div class="card-header border-0 p-4">
                
                <h1 class="h4">1. شروط الأعضاء وبيانات الخصوصية</h1>
                               
            </div>
            <div class="card-body p-4">
                <h1 class="h5">شروط الأعضاء وبيانات الخصوصية</h1>
                <hr>
                <div>
                    <dl>
                        <dd>مرحباً بكم في دبي جي تي' مُرجوع إلى 'نحن'، أو 'نحن'. نحن نقدر خصوصية الخاص بك ونحن ملتزمون بحماية معلوماتك الشخصية. وتوجز سياسة الخصوصية ممارساتنا المتعلقة بجمع البيانات وحمايتها عند استخدام خدماتنا. من خلال الحصول على خدماتنا أو استخدامها، تتفقون على الشروط والشروط المبينة في سياسة الخصوصية هذه. يرجى قراءة هذه السياسة بعناية لفهم كيفية التعامل مع معلوماتكم.</dd>
                        <dt>1. شروط الأعضاء وبيانات الخصوصية</dt>
                        <dd>تتمنى شركة DUBAI GPT الترحيب الحار ("نحن" أو "لنا" أو "خاصتنا"). خصوصيتك مهمة للغاية بالنسبة لنا، ونحن ملتزمون بحماية معلوماتك الشخصية. يحدد بيان الخصوصية هذا بروتوكولاتنا فيما يتعلق بجمع معلوماتك وحمايتها أثناء استخدامك لخدماتنا. من خلال استخدامك أو الوصول إلى خدماتنا، فإنك تخضع للشروط والأحكام الموضحة في بيان الخصوصية هذا. يرجى دراسة هذه السياسة بكل تفاصيلها لفهم النهج الذي نتبعه في التعامل مع معلوماتك.</dd>
                        <dt>2. استخدام الخدمات</dt>
                        <dd>
                            <p>وتشمل خدماتنا طائفة من العروض، بما في ذلك على سبيل المثال لا الحصر في المواقع الشبكية، والتطبيقات المتنقلة، والبرامجيات الحاسوبية وغيرها من المنابر الرقمية. وباستخدام خدماتنا، توافقون على ما يلي:</p>
            
                        </dd>
                        <dt>3.تنفيذ الخدمات</dt>
                        <dd>
                            <p>توفر مؤسستنا مجموعة واسعة من الخدمات، والتي تشمل البرامج والمواقع الإلكترونية وتطبيقات الهاتف المحمول والعديد من المنصات الرقمية الأخرى.</p>
                            <p>  أنت توافق، عند استخدامك لخدماتنا، على:</p>
                            <ul>
                                <li>-سأقدم معلومات دقيقة وشاملة حسب طلبك.</li>
                                <li>- التأكد من الالتزام الصارم بجميع القوانين واللوائح ذات الصلة.</li>
                                <li>-يرجى تجنب المشاركة في أي مشروع من شأنه أن يسبب مشاكل أو أضرار أو تداخلات أو إتلاف خدماتنا، وكذلك الشبكات والأنظمة المرتبطة بها.</li>
                                <li>- الالتزام بشروط الخدمة أو الممارسات الخاصة بعروض DUBAI GPT لخدمات أو ميزات معينة.</li>
                            </ul>
                        </dd>
                        <dt>4.السلوك من قبل المستخدمين</dt>
                        <dd>
                            <p>أنت توافق، من أجل الحفاظ على بيئة محترمة وآمنة لجميع المستخدمين، على عدم القيام بما يلي:</p>
                            <ul>
                                <li>-المشاركة في أي أنشطة غير قانونية أو احتيالية أو ضارة.</li>
                                <li>- إصدار معلومات أو توزيع أي مواد إجرامية أو مسيئة أو تنتهك الحقوق.</li>
                                <li>- يُمنع منعًا باتًا الانخراط في عمليات احتيال أو الادعاء عمدًا بالانتساب إلى DUBAI GPT.</li>
                                <li>-أحد التهديدات المحتملة هو محاولة الوصول بشكل غير قانوني إلى أنظمتنا أو بياناتنا أو حساباتنا.</li>
                                <li>-استخدام أساليب آلية، مثل برامج الكشط أو الروبوتات، للوصول غير المصرح به إلى خدماتنا.</li>
                                <li>-لا ينبغي للمرء أن يضايق أو يلاحق أو ينغمس في أي سلوك ضار آخر يستهدف المستخدمين الآخرين.</li>
                                <li>- جمع أو تخزين غير مصرح به للمعلومات الشخصية الخاصة بمستخدمين آخرين.</li>
                            </ul>
                        </dd>
                        <dt>5.الملكية الفكرية</dt>
                        <dd>
                            <p>تحمي قوانين الملكية الفكرية جميع المواد والمحتوى الموجود على DUBAI GPT، بما في ذلك، على سبيل المثال لا الحصر، النصوص والرسوم التوضيحية والشعارات والصور والبرامج والمواد السمعية والبصرية. بدون الحصول على موافقة كتابية مسبقة منا أو موافقة أصحاب الحقوق اللازمة، لا يجوز لك استخدام المحتوى الخاص بنا أو إعادة إنتاجه أو توزيعه أو تعديله أو إنشاء أعمال مشتقة منه.</p>
                            <p>أنت تمنح DUBAI GPT ترخيصًا عالميًا وغير حصري وخاليًا من حقوق الملكية لاستخدام المحتوى الذي تقوم بتحميله أو إرساله إلى خدماتنا وعرضه وإعادة إنتاجه وتوزيعه، مع الاحتفاظ بملكية المادة.</p>
                        </dd>
                        <dt>6. يتم الحفاظ على الخصوصية</dt>
                        <dd>
                            <p>نحن نولي أهمية كبيرة لخصوصيتك ونلتزم بحماية معلوماتك الشخصية. نحن نوضح بالتفصيل في بيان الخصوصية الخاص بنا كيفية حصولنا على معلوماتك واستخدامها والكشف عنها وحمايتها. أنت تمنح الموافقة على ممارسات البيانات الخاصة بنا على النحو المبين في بيان الخصوصية من خلال الاستفادة من خدماتنا.</p>
                        </dd>
                        <dt>7. إخلاء المسؤولية</dt>
                        <dd>
                            <p>تهدف DUBAI GPT بعناية إلى ضمان توحيد خدماتنا وإمكانية الوصول إليها. ومع ذلك، فإننا لا نضمن عدم انقطاع الخدمة أو خلوها من الأخطاء. دون إشعار مسبق، نحتفظ بالحق في تعليق أو تقييد الوصول إلى خدماتنا مؤقتًا لإجراء التحديثات أو الصيانة أو لأسباب أخرى.</p>
                            <p>- محتوى الطرف الثالث: قد تتضمن خدماتنا اتصالات بمواقع الويب أو التطبيقات أو المحتوى التابع لأطراف ثالثة. DUBAI GPT معفاة من المسؤولية عن مدى ملاءمة أو شرعية أو دقة المحتوى المذكور. فيما يتعلق بالتفاعلات مع محتوى الطرف الثالث، تنطبق شروط وسياسات هذا الطرف الثالث.</p>
                            <p>يرجى ملاحظة أنه على الرغم من أننا نبذل قصارى جهدنا لتقديم معلومات دقيقة وحديثة، إلا أننا لا نستطيع ضمان دقة أو صحة أو موثوقية المحتوى المتاح على DUBAI GPT. أنت تقر بموجب هذا أن أي اعتماد على المعلومات المقدمة هو على مسؤوليتك الخاصة.</p>
                            <p>نحن ننفذ تدابير أمنية كافية لحماية خدماتنا وبياناتك. ومع ذلك، لا يمكن ضمان أمن المعلومات المخزنة على أنظمتنا أو المنقولة عبر الإنترنت. من واجبك ضمان خصوصية بيانات اعتماد تسجيل الدخول الخاصة بك وإخطارنا فورًا بأي نشاط غير مصرح به قد يحدث في حسابك.</p>
                        </dd>
                        <dt>8. القيود المفروضة على المسؤولية</dt>
                        <dd>
                            <p>لن تكون DUBAI GPT والشركات التابعة لها ومديريها ومسؤوليها وموظفيها ووكلائها مسؤولين، إلى الحد الأقصى الذي يسمح به القانون المعمول به، عن أي أضرار مباشرة أو غير مباشرة أو عرضية أو خاصة أو تبعية أو تأديبية قد تنشأ عن أو فيما يتعلق باستخدامك لخدماتنا، حتى لو تم إبلاغنا بإمكانية حدوث مثل هذه الأضرار. تقتصر مسؤوليتنا على أقصى درجة يسمح بها القانون.</p>
                        </dd>
                        <dt>9.التعويض</dt>
                        <dd>
                            <p>أنت بموجب هذا تمنح DUBAI GPT والشركات التابعة لها ومديريها ومسؤوليها وموظفيها ووكلائها مسؤولية التغطية وحمايتهم ضد أي مطالبات والتزامات وأضرار وخسائر وتكاليف ونفقات (بما في ذلك أتعاب المحاماة) التي قد تنشأ نتيجة لذلك. أو فيما يتعلق باستخدامك لخدماتنا، أو خرق هذه الشروط، أو انتهاك سياساتنا.</p>
                        </dd>
                        <dt>10. أي مصالح لطرف ثالث</dt>
                        <p>القانون الحاكم</p>
                        <dd>
                            <p>بغض النظر عن تعارض مبادئ القوانين، يخضع بيان الخصوصية هذا واستخدامك لخدمات DUBAI GPT لقوانين دولة الإمارات العربية المتحدة ويفسر وفقًا لها. السلطة القضائية الوحيدة للنظر في أي نزاعات قد تنشأ فيما يتعلق بسياسة الخصوصية هذه أو بموجبها هي المحاكم الموجودة في دبي، الإمارات العربية المتحدة.</p>                        
                        </dd>
                        <dt>11. معلومات الاتصال</dt>
                        <p>إذا كانت لديك أي استفسارات أو مخاوف أو ملاحظات بخصوص بيان الخصوصية هذا أو عروضنا، فيرجى التواصل معنا على [Dubaigpt@9x].</p>
                        <p>أنت توافق على بيان الخصوصية هذا وتؤكد أنك قرأته وفهمته ووافقت عليه من خلال الاستفادة من خدمات DUBAI GPT. نحن ملتزمون بحماية خصوصيتك وتقديم تجربة مستخدم موثوقة وآمنة، لأننا نقدر ثقتك.</p>
                    </dl>
                </div>
            </div>
        </div>
        
    </section>
</template>
<script>
export default{
    name: "PrivacyPolicyScreen"
}
</script>

<style>
dl dt 
{
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
}
dl dd
{
    font-size: 15px;
    margin-bottom: 10px;
    margin-left: 20px;
}
ul, ol
{
    margin-left: 20px;
}
</style>