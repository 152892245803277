<template>
    <section class="container">
        <div class="card border-0">
            <div class="card-header border-0 p-4">
                
                <h1 class="h4"><dt>DubaiGPT Terms and Conditions </dt> </h1>
                               
            </div>
            <div class="card-body p-4">
                <h1 class="h5">Effective Date: January 1, 2024</h1>
                <hr>
                <div>
                    <dl>
                        <dd>Welcome to DubaiGPT! These terms and conditions outline the rules and regulations for the use of DubaiGPT's website and services. By accessing this website and using our services, you accept these terms and conditions in full. Do not continue to use DubaiGPT's website if you do not accept all of the terms and conditions stated on this page.</dd>
                        <dt>1. Service Use</dt>
                        <dd>
                            <ul>
                                <li>You agree to use DubaiGPT's services only for lawful purposes and in a manner that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the services.</li>
                                <li>You must not use our services to transmit or upload any computer viruses, malware, or any other harmful code, or to engage in any activity that disrupts or damages the operation of our services or website.</li>
                            </ul>
                        </dd>
                        <dt>2. Intellectual Property Rights</dt>
                        <dd>
                            <ul>
                                <li>All intellectual property rights in the content, materials, and services provided by DubaiGPT are owned by or licensed to us. You may not copy, reproduce, distribute, or create derivative works from any content provided by DubaiGPT without our express written permission.</li>
                            </ul>
                        </dd>
                        <dt>3. Third-Party APIs and Data Accuracy</dt>
                        <dd>
                            <ul>
                                <li>DubaiGPT integrates with various third-party APIs to enhance our services and provide you with the most accurate, up-to-date information and functionalities. These third-party services may include, but are not limited to, data analytics, communication platforms, and other AI technologies that complement our offerings.</li>
                                <li>When you interact with services powered by third parties, such third parties may collect information about you independently from us. The collection, use, and sharing of your information by these third parties are subject to their respective privacy policies and terms of service, which we encourage you to review.</li>
                                <li>While we strive to select third-party services that are reputable and reliable, DubaiGPT cannot guarantee the accuracy, completeness, or reliability of the information provided through these third-party APIs. The information obtained from these services is provided "as is" without any representation or endorsement made and without warranty of any kind, whether expressed or implied.</li>
                                <li>DubaiGPT shall not be liable for any errors, inaccuracies, or omissions in the content provided through third-party APIs nor for any loss or damage of any kind incurred as a result of the use of any content posted, transmitted, or otherwise made available via the third-party services integrated with our platform.</li>
                             </ul>
                        </dd>
                        <dt>4. User-Generated Content</dt>
                        <dd>
                            <ul>
                                <li>You retain all rights in, and are solely responsible for, the content that you provide or upload to DubaiGPT, including text, images, and any other materials.</li>
                                <li>By providing content to DubaiGPT, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, distribute, and display that content in connection with the services provided by DubaiGPT.</li>
                            </ul>
                        </dd>
                        <dt>5. Privacy</dt>
                        <dd>
                            <ul>
                                <li>Your use of DubaiGPT's services is subject to our Privacy Policy, which outlines how we collect, use, and protect your personal information.</li>
                            </ul>
                        </dd>
                        <dt>6. Limitation of Liability</dt>
                        <dd>
                            <ul>
                                <li>DubaiGPT shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of our services.</li>
                            </ul>
                        </dd>
                        <dt>7. Indemnification</dt>
                        <dd>
                            <ul>
                                <li>You agree to indemnify and hold harmless DubaiGPT and its affiliates, officers, agents, and employees from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of our services, your violation of these terms and conditions, or your violation of any rights of another.</li>
                            </ul>
                        </dd>
                        <dt>8. Termination</dt>
                        <dd>
                            <ul>
                                <li>We may terminate or suspend your access to our services immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach these terms and conditions.</li>
                            </ul>
                        </dd>
                        <dt>9. Governing Law</dt>
                        <dd>
                            <ul>
                                <li>These terms and conditions shall be governed by and construed in accordance with the laws of India, and you submit to the exclusive jurisdiction of the courts located in India for the resolution of any disputes.</li>
                            </ul>
                        </dd>
                        <dt>10. Changes to Terms and Conditions</dt>
                        <dd>
                            <ul>
                                <li>We reserve the right to modify these terms and conditions at any time. Any changes will be posted on this page, and your continued use of our services after any changes have been made constitutes your acceptance of the new terms and conditions.</li>
                            </ul>
                        </dd>
                        <dt>11. Contact Us</dt>
                        <dd>
                            <ul>
                                <li>If you have any questions or concerns about these terms and conditions, please contact us at <a class="text-secondary" style="text-decoration: none;" href="dubaigpt@9xtechnology.com">info@dubaigpt.com</a></li>
                            </ul>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
        
    </section>
</template>
<script>
export default{
    name: "TermsAndConditionsScreen"
}
</script>

<style>
dl dt 
{
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
}
dl dd
{
    font-size: 15px;
    margin-bottom: 10px;
    margin-left: 20px;
}
ul, ol
{
    margin-left: 20px;
}
</style>